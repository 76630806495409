html,
body,
#root {
  background-color: var(--SECONDARY-GREY);
  font-family: 'Poppins', sans-serif;
  position: relative;
  height: auto;
  min-height: 100% !important;
}

* {
  font-family: 'Poppins', sans-serif;
  line-height: normal;
  font-style: normal;
}

.app-box-header {
  z-index: 5;
  width: 100%;
}

.app-main-box {
  background-color: var(--CLEAR-BACKGROUND) !important;
}

.app-main-box-logged {
  background-color: var(--SECONDARY-GREY);
}

.banner-bold {
  color: var(--MAIN-GREY-TEXT);
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 4px;
}

.subtitle-bold {
  font-weight: 600;
}

.subtitle-bold.margin-bottom {
  margin-bottom: 16px;
}

.subtitle-medium,
.subtitle-bold {
  color: var(--MAIN-GREY);
  font-size: 16px;
}

.subtitle-medium {
  font-weight: 500;
}

.subtitle-medium.margin-bottom {
  line-height: 24px;
  margin-bottom: 16px;
}

.basic-input {
  background-color: var(--INPUT-BACKGROUND) !important;
  color: var(--MAIN-GREY) !important;
  border-color: var(--INPUT-BORDER) !important;
  border-radius: 10px !important;
  height: 53px !important;
  color: var(--MAIN-GREY, #323232);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.li-circle-mark {
  padding-top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--CIRCLE-MARK);
  margin-right: 0.5rem;
  margin-top: 6px;
}

.input-form-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: var(--MAIN-GREY);
  p {
    margin-bottom: 0px !important;
  }
}

.input-form-label.margin-bottom {
  margin-bottom: 15px;
}

.regular-input {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px !important;
  background-color: var(--INPUT-BACKGROUND) !important;
  border-radius: 0;
  display: flex;
  padding: 15px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.regular-input:hover {
  border-color: var(--INPUT-BORDER);
}

.regular-input:focus {
  border-color: var(--INPUT-BORDER);
  box-shadow: none;
}

.main-button,
.secondary-button,
.danger-button {
  border-radius: 10px !important;
  justify-content: center;
  font-family: Poppins;
  align-items: center;
  display: flex;
}

.width-full {
  width: 100%;
}

.main-button {
  background-color: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
}

.secondary-button {
  border: 1px solid var(--MAIN-GREY) !important;
  background: var(--BUTTON-COLOR) !important;
  color: var(--MAIN-GREY) !important;
}

.danger-button {
  background-color: var(--DANGER-COLOR) !important;
  color: var(--MAIN-TEXT) !important;
}

.info-button {
  background-color: var(--SUPORT-BUTTON-BACKGROUND) !important;
  color: var(--MAIN-GREY) !important;
  padding: 5px 15px !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-weight: 500;
  margin-top: 8px;
  width: 50%;
  text-align: center;
}

.accept-btn {
  background: var(--MAIN-GREY) !important;
  color: var(--MAIN-TEXT) !important;
  font-size: 14px !important;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  align-self: stretch;
  padding: 16px 30px;
  font-weight: 600;
  height: 100% !important;
  display: flex;
  width: 100%;
  gap: 10px;
}

.tab-groups {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
}

.modal-header {
  color: var(--MAIN-GREY);
}

.modal-header,
.modal-delete-header {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-delete-header {
  color: var(--DANGER-COLOR) !important;
  margin-bottom: 0;
}

.modal-delete-secondary-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-header-secondary {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  color: var(--MAIN-GREY);
}

.modal-header-secondary-bold {
  font-size: 12px;
  font-weight: 700;
  color: var(--MAIN-GREY);
}

.secondary-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--MAIN-GREY);
}

.group-identification-circle {
  background-color: var(--GROUP-CIRCLE);
  color: var(--MAIN-TEXT);
  font-size: 42.6px;
  font-weight: 400;
  line-height: normal;
}

.group-card-header {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
  margin-top: 0px;
}

.group-card-link {
  color: var(--LINK-COLOR) !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline !important;
  margin-bottom: 4px;
  margin-top: 0px;
}

.group-card-subheader {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-top: 0px;
}

.input-label-text {
  color: var(--MAIN-GREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.required-field {
  color: var(--ERROR-COLOR);
}

/* Separator */
.separator {
  color: var(--mediumGrey);
}
.separator-horizontal {
  border-bottom-width: 1px;
}

.separator-horizontal.margin-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.separator-horizontal.margin-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.separator-vertical {
  border-right-width: 1px;
  height: 100%;
}

.separator-vertical.margin-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.separator-vertical.margin-24 {
  margin-left: 24px;
  margin-right: 24px;
}

/*Sweetalert Toast */
.custom-toast {
  border-radius: 10px;
  border: 1px solid var(--MAIN-GREY);
  background: #fbfbfb;
  color: var(--MAIN-GREY);
  font-size: 14px;
  font-weight: 400;
}
