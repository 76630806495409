.button-styles-hubspot-modal {
  color: var(--MAIN-TEXT) !important;
  background-color: var(--MAIN-GREY-TEXT) !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: 'Inter', sans-serif;
}

.modal-content-hubspot-modal {
  padding: 16px;
}
